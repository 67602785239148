import React from "react";
import styles from "./UIRight.module.scss";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";

const UIRight = (props) => {
  const c = classNames.bind(styles);

  const { language, currentPage, landing, slug, menuActive, setMenuActive, windowDimensions, setClickBoxState, mainBody } = props;
  // console.log(currentPage);

  const color =
    currentPage.slug.en === "landing-page" ||
    currentPage.slug.en === "about" ||
    currentPage.slug.en === "bibliography"
      ? "#DC0000"
      : currentPage.color;

  return (
    <div 
      className={c(
        {main: true}, 
        {hidden: !menuActive && windowDimensions.width < 769}
      )}
      style={{
        borderColor: color
      }}
    >
      <div
        className={c(
          { topButtons: true },
          { visible: !landing },
          { japanese: language === "日本" }
        )}
      >
        <NavLink 
          to={language === "en" ? "/en/about" : "/日本/約"}
          onClick={() => {
            setMenuActive(false)
            setClickBoxState("hidden");
            if (mainBody.current) {
              mainBody.current.scrollTop();
            }
          }}
          >
          <pre
            style={{
              color: slug === "about" || slug === "約" ? color : "#4B4B4B",
            }}
          >
            {language === "en" ? "About" : "序\n文"}
          </pre>
        </NavLink>
        <NavLink 
          to={language === "en" ? "/en/bibliography" : "/日本/参考文献"}
          onClick={() => {
            setMenuActive(false)
            setClickBoxState("hidden");
            if (mainBody.current) {
              mainBody.current.scrollTop();
            }
          }}
        >
          <pre
            style={{
              color:
                slug === "bibliography" || slug === "参考文献"
                  ? color
                  : "#4B4B4B",
            }}
          >
            {language === "en" ? "Bibliography" : "参\n考\n文\n献"}
          </pre>
        </NavLink>
      </div>
      <NavLink
        to={"/日本/" + currentPage.slug.jp}
        className={c(
          { button: true },
          { active: language === "日本" },
          { japanese: true }
        )}
      >
        <p
          style={{
            color: language === "日本" ? color : "#4B4B4B",
          }}
        >
          日 本 語
        </p>
      </NavLink>
      <NavLink
        to={"/en/" + currentPage.slug.jp}
        className={c({ button: true }, { active: language === "en" })}
      >
        <p
          style={{
            color: language === "en" ? color : "#4B4B4B",
          }}
        >
          EN
        </p>
      </NavLink>
    </div>
  );
};

export default UIRight;
