import React, { Fragment } from "react";
import "./styles/globals.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./views/Main";

function App() {
  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Switch key="main-section">
          <Route
            exact
            path="/"
            render={(props) => <Main landing={true} {...props} />}
          />
          <Route
            path="/:lang/:slug"
            render={(props) => (
              <Main landing={false} {...props} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
