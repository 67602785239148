

import React from "react";
import useMousePosition from "../hooks/useMousePosition";
import classes from "./Cursor.module.scss";
import classNames from "classnames/bind";


const Cursor = (props) => {
    const {isTouchDevice, windowDimensions} = props;
    const c = classNames.bind(classes);
    const { clientX, clientY } = useMousePosition();

    return(
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: "none",
      }}
    > 
      <div
        className={c(
          { cursor: true },
          { hidden: clientX <= 0 || clientY <= 0 || isTouchDevice || clientX >= windowDimensions.width-3 || clientY >= windowDimensions.height-3}
        )}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="17.333" height="25.253" viewBox="0 0 17.333 25.253">
            <path id="Path_6" data-name="Path 6" d="M2977.333,3605v25.253l7.172-10.161h10.161Z" transform="translate(-2977.333 -3605)" fill="#4e4d4d"/>
        </svg>

      </div>
    </div>
  );
};

export default Cursor;