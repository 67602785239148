import React, { useState, useEffect, useCallback, useRef } from "react";
import client from "../client.js";

import LandingScreen from "../components/LandingScreen";
import UILeft from "../components/UILeft";
import UIRight from "../components/UIRight";
import TalkBody from "../components/TalkBody";
import Cursor from "../components/Cursor";
import MetaData from "../components/MetaData";
import MenuButton from "../components/MenuButton.js";


const Main = (props) => {
  const { landing, match } = props;
  const { params } = match;
  const { lang, slug } = params;
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [menuActive, setMenuActive] = useState(true);
  const [talksData, setTalksData] = useState(null);
  const [siteData, setSiteData] = useState(null);
  const [clickBoxState, setClickBoxState] = useState("hidden");
  const [pageData, setPageData] = useState({
    slug: {
      en: "",
      jp: "",
    },
  });
  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
  const mainBody = useRef();

  const fetchTalksData = useCallback(async () => {
    client
      .fetch(
        `*[_type == "talk"] | order(year asc) {
          "meta" : {
             "description": metaData.metaDescription {
              en, 
              jp
            },
            "title": metaData.metaTitle {
              en, 
              jp
            },
            "image": metaData.metaImage
          },
          title {
            en,
            jp
          },
					"slug" : {
            "en" : slug.en.current,
            "jp" : slug.jp.current
          },
          year,
          "color": color.hex,
          body {
            en,
            jp
          },
          _type,nextTalk->{
            title {
            en,
            jp
          },
            slug {
              "en" : en.current,
              "jp" : jp.current
            }
          }
        
        } `
      )
      .then((data) => {
        setTalksData(data);
      })
      .catch(console.error);
  }, []);

  const fetchSiteData = useCallback(async () => {
    client
      .fetch(
        `*[_type in ["about", "bibliography", "siteSettings"] ] | order(year asc) {
          "meta" : {
             "description": metaData.metaDescription {
              en, 
              jp
            },
            "title": metaData.metaTitle {
              en, 
              jp
            },
            "image": metaData.metaImage
          },
          title,
          "slug" : {
            "en" : slug.en.current,
            "jp" : slug.jp.current
          },
          body {
            en,
            jp
          },
          _type
        }
          `
      )
      .then((data) => {
        setSiteData(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    if (!talksData) {
      fetchTalksData();
    }
    if (!siteData) {
      fetchSiteData();
    }

    if (
      siteData &&
      talksData &&
      (slug !== pageData.slug.en || slug !== pageData.slug.jp)
    ) {
      if (!slug && !lang) {
        setPageData(siteData[2]);
      }
      if (
        slug === "about" ||
        slug === "約" ||
        slug === "bibliography" ||
        slug === "参考文献"
      ) {
        for (let i = 0; i < siteData.length; i++) {
          if (siteData[i].slug.en === slug || siteData[i].slug.jp === slug) {
            setPageData(siteData[i]);
          }
        }
      } else {
        for (let i = 0; i < talksData.length; i++) {
          if (talksData[i].slug.en === slug || talksData[i].slug.jp === slug) {
            setPageData(talksData[i]);
          }
        }
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize();
    };
  }, [
    talksData,
    fetchTalksData,
    siteData,
    fetchSiteData,
    lang,
    pageData.slug.en,
    pageData.slug.jp,
    slug,
  ]);

  if (!pageData || !siteData || !talksData) {
    return null;
  }

 // console.log(talksData);

  return (
    <div
      style={{
        width: windowDimensions.width,
        height: windowDimensions.height,
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <MetaData 
        page={pageData}
        language={lang}
      />
      <MenuButton 
        page={pageData}
        windowDimensions={windowDimensions}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
      />
      <style type="text/css">
        {
          "::selection {background:" +
          pageData.color +
          "; }::-moz-selection {background:" +
          pageData.color +
          "; color: #4B4B4B}" + 
          "span::selection {background: #4B4B4B;  color:" +
            pageData.color + "}span::-moz-selection {background: #4B4B4B; color:" +
          pageData.color +
          ";}"}
      </style>
      <Cursor
        isTouchDevice={isTouchDevice}
        windowDimensions={windowDimensions}
      />
      <UILeft
        language={lang}
        talksData={talksData}
        slug={slug}
        currentPage={pageData}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        windowDimensions={windowDimensions}
        setClickBoxState={setClickBoxState}
        mainBody={mainBody}
      />
      <TalkBody 
        language={lang} 
        page={pageData} 
        windowDimensions={windowDimensions}
        isTouchDevice={isTouchDevice}
        clickBoxState={clickBoxState}
        setClickBoxState={setClickBoxState}
        mainBody={mainBody}
      />
      
      <UIRight
        language={lang}
        currentPage={pageData}
        landing={landing}
        talksData={talksData}
        slug={slug}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        windowDimensions={windowDimensions}
        setClickBoxState={setClickBoxState}
        mainBody={mainBody}
      />
      <LandingScreen landing={landing} />
    </div>
  );
};

export default Main;
