import styles from "./HoverBox.module.scss";
import classNames from "classnames/bind";
import useMousePosition from "../hooks/useMousePosition";

const HoverBox = (props) => {
  const c = classNames.bind(styles);
  const { clientX, clientY } = useMousePosition();
  const {
    boxState,
    boxData,
    color,
    language,
    windowDimensions,
    isTouchDevice,
    setBoxState,
  } = props;

  // console.log(boxData);

  if (!boxData) {
    return null;
  }

  

  if (boxData.type === "text") {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: windowDimensions.width > 768 ? "auto" : "calc(100% - 15px)",
        }}
        onClick={() => {
          setBoxState("hidden");
        }}
      >
        <div
          className={c({ textBox: true }, { hidden: boxState === "hidden" }, {right: clientX > windowDimensions.width * 0.5}, {up: clientY > windowDimensions.height * 0.4}, {wide: boxData.text.length > 200})}
          style={{
            left: windowDimensions.width > 768 ? clientX : "0px",
            top: windowDimensions.width > 768 ? clientY : "0px",
            borderColor: color,
          }}
        >
          <p className={c({ text: true }, { japanese: language === "日本" })}>
            {boxData.text}
          </p>
          <div
            className={c(
              { close: true },
              { visible: windowDimensions.width < 769 || isTouchDevice }
            )}
            onClick={() => {
              setBoxState("hidden");
            }}
          >
            <div
              className={c({ cross: true })}
              style={{
                transform: "rotate(45deg)",
                backgroundColor: color,
              }}
            ></div>
            <div
              className={c({ cross: true })}
              style={{
                transform: "rotate(-45deg)",
                backgroundColor: color,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  } else if (
    boxData.type === "image" &&
    windowDimensions.width > 769 &&
    !isTouchDevice
  ) {
    //console.log(boxData.images[0].imagesm.naturalHeight)
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          pointerEvents: "none",
        }}
        onClick={() => {
          setBoxState("hidden");
        }}
      >
        <div
          className={c({ imageBox: true }, { hidden: boxState === "hidden" }, {right: clientX > windowDimensions.width * 0.4}, {portrait: boxData.images[0].portrait}, {up: clientY > windowDimensions.height * 0.4})}
          style={{
            left: windowDimensions.width < 769 ? 0 : clientX > windowDimensions.width * 0.5 ? clientX - 15 : clientX + 15,
            top: windowDimensions.width < 769 ? 0 : clientY,
            width: "auto",
            height: "auto",
            transform:  windowDimensions.width < 769 ? "" : `translate(${clientX > windowDimensions.width * 0.5 ? "-100%" : "0px"},${clientY > windowDimensions.height * 0.4 ? "-100%" : "0px"})`
          }}
        >
          
          <img
            src={boxData.images[0].imagesm}
            alt={boxData.images[0].caption}
            className={c({ image: true }, {portrait: boxData.images[0].portrait})}
            style={{
              borderColor: color,
              backgroundColor: color,
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default HoverBox;
