import React, { useState } from "react";
import styles from "./UILeft.module.scss";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";
import ScrollArea from "./scrollBar/ScrollArea";

const ListElement = (props) => {
  const c = classNames.bind(styles);
  const { talk, language, slug, setMenuActive, setClickBoxState, mainBody } = props;
  const color = talk.color;
  const [colorActive, setColorActive] = useState(false);

  return (
    <NavLink
      to={language === "en" ? talk.slug.en : talk.slug.jp}
      className={styles.talk}
      style={{
        color:
          slug === talk.slug.en || slug === talk.slug.jp || colorActive
            ? color
            : "#4B4B4B",
      }}
      onMouseEnter={() => {
        setColorActive(true);
      }}
      onMouseLeave={() => {
        setColorActive(false);
      }}
      onClick={() => {
        setColorActive(false);
        setMenuActive(false);
        setClickBoxState("hidden");
        if (mainBody.current) {
          mainBody.current.scrollTop();
        }
      }}
    >
      <p className={styles.date}>{talk.year}</p>
      <div
        className={c({ talkTitle: true }, { japanese: language === "日本" })}
      >
        <p>{language === "en" ? talk.title.en : talk.title.jp}</p>
      </div>
    </NavLink>
  );
};

const UISide = (props) => {
  const c = classNames.bind(styles);
  const { language, currentPage, talksData, slug, menuActive, setMenuActive, windowDimensions, setClickBoxState, mainBody } = props;

  const color =
  currentPage.slug.en === "landing-page" ||
  currentPage.slug.en === "about" ||
  currentPage.slug.en === "bibliography"
      ? "#DC0000"
      : currentPage.color;
      
  return (
    <div
      className={c(
        {main: true}, 
        {hidden: !menuActive && windowDimensions.width < 769}
      )}
      style={{
        borderColor:
          currentPage._type === "talk" ? currentPage.color : "#DC0000",
      }}
    >
      <div
        className={c({ title: true }, { japanese: language === "日本" })}
        style={{
          borderColor:
            currentPage._type === "talk" ? currentPage.color : "#DC0000",
        }}
      >
        <h2>{language === "en" ? "Talks at Shogyoji" : " 正行寺講話"}</h2>
      </div>
      <ScrollArea
        className={styles.talkList}
        speed={0.4}
        horizontal={false}
        smoothScrolling={true}
        vertical={true}
        verticalContainerStyle={{
          borderLeftColor: color,
        }}
        verticalScrollbarStyle={{
          backgroundColor: color,
        }}
        contentStyle={{
          paddingTop: windowDimensions.width < 1200 ? "10px" : "20px",
          paddingBottom: "20px",
          paddingLeft: windowDimensions.width < 1200 ? "10px" : "20px",
          paddingRight: "35px",
        }}
        stopScrollPropagation={true}
                >
        {talksData.map((talk, index) => (
          <ListElement
            talk={talk}
            key={index}
            slug={slug}
            language={language}
            setMenuActive={setMenuActive}
            setClickBoxState={setClickBoxState}
            mainBody={mainBody}
          />
        ))}
      </ScrollArea>
    </div>
  );
};

export default UISide;
