import React, { useState } from "react";
import styles from "./TalkBody.module.scss";
import classNames from "classnames/bind";
import imageUrlBuilder from "@sanity/image-url";
import client from "../client";
import BlockContent from "@sanity/block-content-to-react";
import HoverBox from "./HoverBox";
import ClickBox from "./ClickBox";
import ScrollArea from "./scrollBar/ScrollArea";
import {NavLink} from "react-router-dom"

let boxes = [];

const TalkBody = (props) => {
  const c = classNames.bind(styles);
  const { language, page, windowDimensions, isTouchDevice, clickBoxState, setClickBoxState, mainBody } = props;
  const [currentBox, setCurrentBox] = useState(null);
  const [boxState, setBoxState] = useState("hidden");

  const builder = imageUrlBuilder(client);
  const urlFor = (source) => {
    return builder.image(source);
  };



  const color =
    page.slug.en === "landing-page" ||
    page.slug.en === "about" ||
    page.slug.en === "bibliography"
      ? "#DC0000"
      : page.color;

  const serializers = {
    types: {
      poem: (props) => {
        return (
          <div className={styles.poem}>
            <pre>{props.node.poemText}</pre>
          </div>
        );
      },
      indentedParagraph: (props) => {
        return (
          <div className={styles.paragraph}>
            <div className={styles.bullet}>
              <p>{props.node.bulletCharacter}</p>
            </div>
            <div className={styles.indent}>
              <pre>{props.node.paragraphText}</pre>
            </div>
          </div>
        );
      },
      subtitle: (props) => {
        return (
          <div className={styles.subtitle}>
              <p>{props.node.subText}</p>
          </div>
        );
      },
    },
    marks: {
      textBox: (props) => {
        const box = {
            type: 'text',
            text: props.mark.text,
            target: props.markKey
        }
        let duplicate = false;
        boxes.map((i) => {
          if (i.target === box.target) {
            duplicate = true;
            
          }return null;
        })
        if (!duplicate) {
          boxes.push(box);
        }

        return (
          <span
            style={{ color: color }}
            key={props._key}
            id={props.markKey}
            onMouseEnter={() => {
              boxes.map((box, index) => {
                if (box.target === props.markKey) {
                  setCurrentBox(index);
                  setBoxState('visible');
                }
                return null;
              })
            }}
            onMouseLeave={() => {
              setBoxState('hidden');
            }}
          >
            {props.children}
          </span>
        );
      },

      imageBox: (props) => {
       // console.log(props);
        const box = {
          type: 'image',
          images:
            props.mark.images.map((image) => {
              return {
                caption: image.caption,
                imagexs: urlFor(image.img).width(50).blur(3).url(),
                imagesm: urlFor(image.img).width(400).url(),
                imagelg: urlFor(image.img).width(700).url(),
                portrait: image.portrait
              }

            }),
          target: props.markKey
        }
        let duplicate = false;
        boxes.map((i) => {
          if (i.target === box.target) {
            duplicate = true;
          }
          return null;
        })
        if (!duplicate) {
          boxes.push(box);
        }
        
        return (
          <span
            style={{ color: color }}
            key={props._key}
            id={props.markKey}
              onMouseEnter={() => {
                boxes.map((box, index) => {
                  if (box.target === props.markKey) {
                    setCurrentBox(index);
                    setBoxState('visible');
                    
                  }
                  return null;
                })
              }}
              onMouseLeave={() => {
                setBoxState('hidden');
              }}
              onClick={() => {
                boxes.map((box, index) => {
                  if (box.target === props.markKey) {
                    setCurrentBox(index);
                    setBoxState('hidden');
                    setClickBoxState('visible');
                  }
                  return null;
                })
              }}
          >
            {props.children}
          </span>
        );
      },
    },
  };

  let title;
  if (page._type === "talk") {
    if (language === "en") {
      title = page.title.en;
    } else {
      title = page.title.jp;
    }
  } else if (page._type === "about") {
    if (language === "en") {
      title = "About the Talks at Shogyoji";
    } else {
      title = "正行寺講話について";
    }
  } else if (page._type === "bibliography") {
    if (language === "en") {
      title = "Bibliography";
    } else {
      title = "参考文献";
    }
  } else {
    title = "";
  }

  if(!page.body) { return null; }

  return (
    <ScrollArea 
      className={c({ main: true })}
      ref={mainBody}
      speed={1}
        horizontal={false}
        smoothScrolling={true}
        vertical={true}
        verticalContainerStyle={{
          borderLeftColor: color,
        }}
        verticalScrollbarStyle={{
          backgroundColor: color,
        }}
        stopScrollPropagation={true}
    >
      <ClickBox 
        boxData={boxes[currentBox] ? boxes[currentBox] : null}
        setClickBoxState={setClickBoxState}
        clickBoxState={clickBoxState}
        color={color}
        language={language}  
      />
      <HoverBox
        boxData={boxes[currentBox] ? boxes[currentBox] : null}
        boxState={boxState}
        setBoxState={setBoxState}
        color={color}
        language={language}     
        windowDimensions={windowDimensions} 
        isTouchDevice={isTouchDevice}
        />
      <div className={c({ title: true }, {japanese: language === "日本"})}>
        <p>{title}</p>
      </div>
      <div className={c({ body: true }, {japanese: language === "日本" && page._type !== "bibliography"})}>
        <BlockContent
          blocks={language === "en" ? page.body.en : page.body.jp}
          serializers={serializers}
        />
      </div>
      <div className={c({ nextPage: true}, {japanese: language === "日本"})}> 
        {
          page.nextTalk ? 
          (<NavLink
            to={language === "en" ? page.nextTalk.slug.en : page.nextTalk.slug.jp}
            style={{color: color}}
            onClick={() => {
              //console.log(mainBody.current.scrollArea.scrollTop()) 
            }}
          >
            {language === "en" ? "Read Next: " +  page.nextTalk.title.en : "次を読む: " +  page.nextTalk.title.jp}
          </NavLink>)
          : ''
        }
      </div>
    </ScrollArea>
  );
};

export default TalkBody;
