import React from 'react';
import styles from "./MenuButton.module.scss"
import classNames from "classnames/bind";

const MenuButton = (props) => {
    const { page, windowDimensions, menuActive, setMenuActive } = props;
    const c = classNames.bind(styles);

    const color =
    page.slug.en === "landing-page" ||
    page.slug.en === "about" ||
    page.slug.en === "bibliography"
      ? "#DC0000"
      : page.color;

    if (windowDimensions.width > 768) {return null}

    const toggleMenu = () => {
        if (menuActive) {
            setMenuActive(false)
        } else {
            setMenuActive(true)
        }
    }

    return (
        <div 
            className={c(
                {main: true}
            )}
            onClick={toggleMenu}
        >
            <div 
                className={c(
                    {line: true}
                )}
                style={{
                    backgroundColor: color,
                    top: '0px',
                    left: '0px',
                }}    
            ></div>
            <div 
                className={c(
                    {line: true}
                )}
                style={{
                    backgroundColor: color,
                    top: 'calc(50% - 1px)',
                    left: '0px',
                }}    
            ></div>
            <div 
                className={c(
                    {line: true}
                )}
                style={{
                    backgroundColor: color,
                    bottom: '0px',
                    left: '0px',
                }}    
            ></div>
           
        </div>
    );
};

export default MenuButton;