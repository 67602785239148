import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../client";
import Helmet from "react-helmet";

const MetaData = (props) => {
  const { page, language } = props;

  const builder = imageUrlBuilder(client);
  const urlFor = (source) => {
    return builder.image(source);
  };

  if (!page.meta) {
    return null;
  }

  const color =
    page.slug.en === "landing-page" ||
    page.slug.en === "about" ||
    page.slug.en === "bibliography"
      ? "#DC0000"
      : page.color;

  const data = page.meta;
  return (
    <Helmet>
      <meta name="theme-color" content={color} />
      <title>{data.title ? (language === "en" || !language ? data.title.en : "正行寺講話") : "Talks At Shogyoji"}</title>
      <meta
        name="description"
        content={data.description ? (language === "en" || !language ? data.description.en : data.description.jp): ""}
      />
      <meta property="og:title" content={data.title ? (language === "en" || !language ? data.title.en : "正行寺講話") : "Talks At Shogyoji"} />
      <meta property="og:url" content="test" />
      <meta property="og:image" content={data.image ? urlFor(data.image).width(1200).height(600).url() : ""} />
      <meta property="og:description" content={data.description ? (language === "en" || !language ? data.description.en : data.description.jp): ""} />
      <meta name="twitter:title" content={data.title ? (language === "en" || !language ? data.title.en : "正行寺講話") : "Talks At Shogyoji"}/>
        <meta name="twitter:description" content={data.description ? (language === "en" || !language ? data.description.en : data.description.jp): ""}/>
        <meta name="twitter:image" content={data.image ? urlFor(data.image).width(1200).height(600).url() : ""}/>
        <meta name="twitter:image:alt" content={data.title ? (language === "en" || !language ? data.title.en : "正行寺講話") : "Talks At Shogyoji"}/>
    </Helmet>
  );
};

export default MetaData;
