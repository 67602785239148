import React, { Fragment, useState } from "react";
import classes from "./ClickBox.module.scss";
import classNames from "classnames/bind";

const ClickBox = (props) => {
  const c = classNames.bind(classes);
  const { boxData, clickBoxState, language, setClickBoxState, color } = props;
  const [currentImage, setCurrentImage] = useState(0);

  // console.log(boxData);

  if (!boxData || boxData.type !== "image") {
    return null;
  }

 
  if (currentImage > boxData.images.length - 1) {
    setCurrentImage(0);
  }

  const prevImage = () => {
    if (currentImage === 0) {
      setCurrentImage(boxData.images.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const nextImage = () => {
    if (currentImage === boxData.images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  return (
    <div className={c({ main: true }, { hidden: clickBoxState === "hidden" })}>
      <div
        className={c({ close: true })}
        onClick={() => {
          setClickBoxState("hidden");
        }}
      >
        <div
          className={c({ cross: true })}
          style={{
            transform: "rotate(45deg)",
            backgroundColor: color,
          }}
        ></div>
        <div
          className={c({ cross: true })}
          style={{
            transform: "rotate(-45deg)",
            backgroundColor: color,
          }}
        ></div>
      </div>

      <div
        className={c(
          { button: true },
          { previous: true },
          { visible: boxData.images.length > 1 }
        )}
        onClick={prevImage}
      >
        <div
          className={c({ arrow: true })}
          style={{
            transform: "rotate(45deg)",
            backgroundColor: color,
          }}
        ></div>
        <div
          className={c({ arrow: true })}
          style={{
            transform: "rotate(-45deg)",
            backgroundColor: color,
          }}
        ></div>
      </div>

      <div
        className={c(
          { button: true },
          { next: true },
          { visible: boxData.images.length > 1 }
        )}
        onClick={nextImage}
      >
        <div
          className={c({ arrow: true })}
          style={{
            transform: "rotate(45deg)",
            backgroundColor: color,
          }}
        ></div>
        <div
          className={c({ arrow: true })}
          style={{
            transform: "rotate(-45deg)",
            backgroundColor: color,
          }}
        ></div>
      </div>

      {boxData.images.map((image, index) => (
        <Fragment
            key={index}
        >
          <div
            className={c(
              { gallery: true },
              { visible: index === currentImage }
            )}
            
          >
            <img
              src={image.imagelg}
              alt={image.caption}
              className={c(
                {image: true},
                {portrait: image.portrait}
              )}
              style={{
                borderColor: color,
                backgroundColor: color,
              }}
            />
          </div>
          <div
            className={c(
              { caption: true },
              { visible: index === currentImage },
              { japanese: language === "日本" }
            )}
          >
            <p>{image.caption}</p>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default ClickBox;
